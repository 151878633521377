import { MainContainer } from "../style/Style";
import { Routes, Route } from "react-router-dom";
import PassportCreateScene from "../features/passport-create/PassportCreateScene";
import PassportViewScene from "../features/passport-view/PassportViewScene";
import TermsAndConditionsScene from "../features/terms-and-conditions/TermsAndConditionsScene";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PassportCreateSuccessScene from "../features/passport-create/PassportCreateSuccessScene";
import { WithTracker } from "../withTracker";

export default function Main() {
  return (
    <MainContainer>
      <WithTracker>
        <Routes>
          <Route path="/" element={<PassportCreateScene />} />
          <Route path="/create" element={<PassportCreateScene />} />
          <Route path="/success" element={<PassportCreateSuccessScene />} />
          <Route
            path="/success/:passport_id"
            element={<PassportCreateSuccessScene />}
          />
          <Route path="/view/:passport_id" element={<PassportViewScene />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsScene />}
          />
        </Routes>
      </WithTracker>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </MainContainer>
  );
}

import { H1, H2, OrderedList } from "../../style/Style";

export default function TermsAndConditionsScene() {
  return (
    <>
      <H1>Terms and conditions</H1>
      <p>Last updated: 8th April 2023</p>
      <H2>TL;DR: Version</H2>
      <OrderedList>
        <li>
          Waste Passport is an app that helps you store and share information
          about waste transportation using QR codes. It's created by Peter
          Kisel, and you can reach him at peter@raavn.net.
        </li>
        <li>
          Anyone can use the app, but it's mainly designed for people in Europe.
          We don't take any responsibility for any problems caused by the app,
          and you use it at your own risk.
        </li>
        <li>
          We don't ask you to create an account, but we do collect your email
          address to send you important emails about the app.
        </li>
        <li>
          You can create a waste passport and share it with others using a QR
          code. Don't copy or reproduce the app without our permission.
        </li>
        <li>
          The app is currently free to use, but we might charge fees in the
          future.
        </li>
        <li>
          We don't promise that the app will always work perfectly, and we might
          need to do maintenance sometimes. We're not responsible for any issues
          you might have while using the app.
        </li>
        <li>
          Since there are no user accounts, we don't have rules about account
          termination or suspension.
        </li>
        <li>
          We're not responsible for any problems or losses you might have
          because of using Waste Passport. You use the app at your own risk.
        </li>
        <li>
          If there's a disagreement about using the app, it will be handled
          according to the laws of Prague, Czechia.
        </li>
        <li>
          We might change these rules in the future. If we do, we'll email you
          about the changes at least 7 days before they happen. If you don't
          agree with the new rules, you should stop using the app. If you keep
          using the app, that means you agree to the new rules.
        </li>
      </OrderedList>

      <H2>Full version</H2>
      <OrderedList>
        <li>
          <strong>General Information</strong>
          <br />
          Welcome to Waste Passport, a web app owned and operated by Peter Kisel
          ("we," "us," or "our"). By using the Waste Passport app, you agree to
          be bound by these Terms and Conditions. You may contact us at
          peter@raavn.net with any questions or concerns regarding these Terms
          and Conditions.
        </li>
        <li>
          <strong>Purpose and Scope</strong>
          <br /> Waste Passport is designed to store digital information about
          transported batches of waste, which can be shared via a QR code. The
          app is intended for use within Europe and is provided as-is, with no
          liability for any damage caused by using the app. Any limitations on
          the use of the app are outlined in these Terms and Conditions.
        </li>
        <li>
          <strong>User Registration and Account Management</strong>
          <br /> There is no user registration for Waste Passport. The only
          personal information collected is the user's email address for
          transactional emails.
        </li>
        <li>
          <strong>User Rights and Responsibilities</strong>
          <br /> a. Acceptable Use: Users may create a waste passport by filling
          out the form and sharing the waste passport via QR code. b.
          Intellectual Property Rights: All generated data and the Waste
          Passport codebase are the exclusive property of us. The app and its
          contents may not be cloned, copied, or otherwise reproduced without
          our express written permission. c. User Interactions: There are
          currently no rules for user interactions, such as reviews or comments,
          as this functionality is not yet available.
        </li>
        <li>
          <strong>Fees and Payment Terms</strong>
          <br /> Waste Passport is currently offered free of charge. We reserve
          the right to change the fees or payment terms in the future.
        </li>
        <li>
          <strong>Service Availability and Maintenance</strong>
          <br /> a. Waste Passport is provided without any performance
          guarantees and should be considered a complement to standard services.
          <br />
          b. Scheduled maintenance and downtime notifications may occur as
          necessary. <br />
          c. We disclaim any liability for app malfunctions or errors.
        </li>
        <li>
          <strong>Termination and Suspension</strong>
          <br /> Since there are no user accounts, termination and suspension do
          not apply.
        </li>
        <li>
          <strong>Legal Disclaimers and Limitations of Liability</strong>
          <br />
          a. Waste Passport is provided without warranties or representations,
          express or implied. The app should be used as a complement to a
          standard process. <br />
          b. We are not liable for any direct or indirect damages resulting from
          the use or inability to use Waste Passport. <br />
          c. Users agree to indemnify and hold harmless Peter Kisel from any
          claims, losses, or damages arising from their use of Waste Passport.
        </li>
        <li>
          <strong>Dispute Resolution</strong>
          <br /> These Terms and Conditions shall be governed by and construed
          in accordance with the laws of Prague, Czechia. Any disputes arising
          out of or in connection with the use of Waste Passport shall be
          subject to the exclusive jurisdiction of the courts of Prague,
          Czechia.
        </li>
        <li>
          <strong>Changes to Terms and Conditions</strong>
          <br /> a. We reserve the right to update these Terms and Conditions at
          our discretion. We will provide at least seven (7) days' notice of any
          changes by emailing every unique email address we have on record.{" "}
          <br />
          b. Users will be notified of changes to the Terms and Conditions via
          email. <br />
          c. If you do not accept the updated Terms and Conditions, you must
          cease using Waste Passport. Continued use of the app after receiving
          notice of changes constitutes your acceptance of the updated Terms and
          Conditions.
        </li>
      </OrderedList>
    </>
  );
}

import logo from "../img/logo-alpha-white.svg";
import { Logo, HeaderContainer } from "../style/Style";

export default function Header() {
  return (
    <HeaderContainer className="App-header">
      <Logo src={logo} alt="logo" />
    </HeaderContainer>
  );
}

import { Hr, CenteredContainer } from "../../style/Style";
import {
  TextInput,
  Form,
  Label,
  SwitchInput,
  Select,
  TextArea,
} from "../../style/Input";
import { ButtonSubmitPrimary } from "../../style/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Passport } from "../../types";
import { useSubmitForm } from "../../hooks/use-submit-form";

export default function PassportCreateScene() {
  const [formValues, setFormValues] = useState<Passport>({
    title: "",
    origin: "",
    destination: "",
    hazardous_waste: false,
    transport_method: "",
    consignment_notes: "",
    terms_agreement: false,
    conformity_declaration: false,
    email: "",
  });

  const {
    submitForm,
    // isLoading,
    // error
  } = useSubmitForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleChange(event: React.FormEvent) {
    const { target } = event;
    const input_name = (target as HTMLInputElement).name;
    let input_value: string | boolean = (target as HTMLInputElement).value;

    if (
      (input_name === "hazardous_waste" && input_value === "on") ||
      (input_name === "terms_agreement" && input_value === "on") ||
      (input_name === "conformity_declaration" && input_value === "on")
    ) {
      input_value = true;
    }

    setFormValues((prevData) => ({
      ...prevData,
      [input_name]: input_value,
    }));
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);

    // validations
    const errors = {
      email: "false",
    };

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      errors.email = "Invalid email address";
    }

    await submitForm(formValues);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <Label htmlFor="title">What are you shipping?</Label>
        <TextInput
          onChange={handleChange}
          type="text"
          name="title"
          placeholder="Pressed PET bottles"
        />
      </div>
      <div>
        <Label htmlFor="origin">Where are you shipping it from?</Label>
        <TextInput
          onChange={handleChange}
          type="text"
          name="origin"
          placeholder="Spezzia, Italy"
        />
      </div>
      <div>
        <Label htmlFor="destination">What is the destination?</Label>
        <TextInput
          onChange={handleChange}
          type="text"
          name="destination"
          placeholder="Klagenfurt, Austria"
        />
      </div>
      <div>
        <Label htmlFor="hazardous_waste">Is it hazardous?</Label>
        <CenteredContainer>
          <div>No</div>{" "}
          <SwitchInput onChange={handleChange} name="hazardous_waste" />{" "}
          <div>Yes</div>
        </CenteredContainer>
      </div>
      <div>
        <Label htmlFor="transport_method">How are you transporting it?</Label>
        <Select onChange={handleChange} name="transport_method">
          <option value={undefined}>-- please select --</option>
          <option value={"road"}>Road</option>
          <option value={"rail"}>Rail</option>
          <option value={"sea"}>Sea</option>
          <option value={"air"}>Air</option>
        </Select>
      </div>
      <div>
        <Label htmlFor="consignment_notes">
          Consignment notes (Anything helps)
        </Label>
        <TextArea
          onChange={handleChange}
          name="consignment_notes"
          placeholder={`Any additonal notes or detailed information about the waste being transported, including the composition, hazard classification, and the relevant legal requirements for transport and disposal.`}
        />
      </div>
      <div>
        <Hr />
      </div>
      <div>
        <Label htmlFor="terms_agreement">
          I agree with the{" "}
          <Link to="/terms-and-conditions">terms and conditions</Link> of Waste
          Passport
        </Label>
        <CenteredContainer>
          <div>No</div>
          <SwitchInput onChange={handleChange} name="terms_agreement" />
          <div>Yes</div>
        </CenteredContainer>
      </div>
      <div>
        <Label htmlFor="conformity_declaration">
          Declaration of conformity: I hereby declare that I have truthfully
          filled this form and fulfilled all the legal obligations to transport
          this material
        </Label>
        <CenteredContainer>
          <div>No</div>
          <SwitchInput onChange={handleChange} name="conformity_declaration" />
          <div>Yes</div>
        </CenteredContainer>
      </div>
      <div>
        <Hr />
      </div>
      <div>
        <Label htmlFor="email">Your email</Label>
        <TextInput
          type="text"
          onChange={handleChange}
          name="email"
          placeholder="name@email.com"
        />
      </div>
      <div>
        {isSubmitting ? (
          <p>Submitting, please wait...</p>
        ) : (
          <ButtonSubmitPrimary>Create passport</ButtonSubmitPrimary>
        )}
      </div>
    </Form>
  );
}

import { useState, useEffect } from "react";
import { Passport } from "../types";

function useFetchPassport(id: string) {
  const [data, setData] = useState<Passport | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null | unknown>(null);

  useEffect(() => {
    async function fetchPassport() {
      try {
        setLoading(true);
        const response = await fetch(
          `https://api.waste-passport.com/waste-passport/api/passport/${id}`
        );
        const json = await response.json();
        setData(json);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    fetchPassport();
  }, [id]);

  return { data, loading, error };
}

export default useFetchPassport;

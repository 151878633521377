import styled from "styled-components";
import { Color, Font } from "./Style";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TextInput = styled.input`
  padding: 1rem;
  ${Font.code}
  border-radius: 0.5rem;
  border: 1px solid ${Color.grey};
  font-size: 1rem;
  color: ${Color.black};
  display: block;
  width: 30rem;
  background-color: ${Color.white};
  transition: border-color 0.3s ease-in-out;

  &:focus {
    outline: none;
    border-color: ${Color.accent};
    box-shadow: 0 2px 4px ${Color.rgba.light.green};
  }

  &::placeholder {
    color: ${Color.rgba.light.grey};
    ${Font.code}
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const Select = styled.select`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${Color.grey};
  font-size: 1rem;
  ${Font.code}
  color: ${Color.black};
  display: block;
  width: 30rem;
  background-color: ${Color.white};
  transition: border-color 0.3s ease-in-out;

  &:focus {
    outline: none;
    border-color: ${Color.accent};
    box-shadow: 0 2px 4px ${Color.rgba.light.green};
  }

  &::placeholder {
    color: ${Color.rgba.light.grey};
  }
  & option {
    ${Font.code}
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const TextArea = styled.textarea`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${Color.grey};
  font-size: 1rem;
  ${Font.code}
  line-height: 1.75rem;
  color: ${Color.black};
  display: block;
  width: 30rem;
  height: 9rem;
  background-color: ${Color.white};
  transition: border-color 0.3s ease-in-out;

  &:focus {
    outline: none;
    border-color: ${Color.accent};
    box-shadow: 0 2px 4px ${Color.rgba.light.green};
  }

  &::placeholder {
    color: ${Color.rgba.light.grey};
    ${Font.code}
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  color: ${Color.grey};
  display: block;
  width: 100%;
  line-height: 2.5rem;
`;

export const SwitchInput = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 4rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${Color.grey};
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.5s;

  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    background-color: ${Color.white};
    transition: transform 0.2s ease-in-out;
  }

  &:checked {
    background-color: ${Color.secondary};
  }

  &:checked:before {
    transform: translateX(2rem);
  }
`;

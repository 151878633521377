import styled from "styled-components";

// colors
export const Color = {
  black: "#12100E",
  white: "#FEFFFC",
  blue: "#2C3E50",
  // green: "#4DAA57",
  secondary: "#3498DB",
  accent: "#F39C12",
  grey: "#6B717E",
  greyLight: "#E5E5E5",
  rgba: {
    black: "rgba(18, 16, 14, 1)",
    white: "rgba(254, 255, 252, 1)",
    blue: "rgba(7, 190, 184, 1)",
    green: "rgba(77, 170, 87, 1)",
    grey: "rgba(107, 113, 126, 1)",
    light: {
      black: "rgba(18, 16, 14, 0.1)",
      white: "rgba(254, 255, 252, 0.1)",
      blue: "rgba(7, 190, 184, 0.1)",
      green: "rgba(77, 170, 87, 0.1)",
      grey: "rgba(107, 113, 126, 0.4)",
    },
  },
  gradient: "linear-gradient(to right, #2C3E50, #3498DB)",
};

// fonts
export const Font = {
  family:
    "font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont;",
  code: "font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;",
  size: {
    light: "font-weight: 300;",
    regular: "font-weight: 400;",
    bold: "font-weight: 700;",
    extrabold: "font-weight: 800;",
  },
};

// main elements

export const HeaderContainer = styled.header`
  padding: 1rem 2rem;
  background: ${Color.blue};
`;
// export const HeaderContainer = styled.header`
//   padding: 1rem 2rem;
//   background-image: ${Color.gradient};
// `;

export const MainContainer = styled.main`
  padding: 2rem 2rem 4rem 2rem;
  min-height: calc(100vh - 310px);
`;

export const FooterContainer = styled.footer`
  padding: 2rem 1rem 4rem 1rem;
  height: 8rem;
  display: flex;
  justify-content: space-between;
  gap: 6.25rem;
  background: ${Color.black};
  color: ${Color.white};
`;

export const Logo = styled.img`
  width: 20rem;
`;

export const FooterLogo = styled.img`
  height: 1.5rem;
`;

// containers

export const AppContainer = styled.div`
  & a {
    color: ${Color.blue};
    text-decoration: none;
    transition: color 1s;
    &:hover {
      color: ${Color.accent};
      text-decoration: underline;
    }
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
`;

// texts
export const H1 = styled.h1`
  color: ${Color.blue};
  font-size: 2.5rem;
`;

export const H2 = styled.h2`
  color: ${Color.blue};
  font-size: 1.75rem;
`;

// ui elements
export const Hr = styled.hr`
  border: none;
  border-top: 1px dashed #ccc;
  height: 0;
`;

export const VerticalLine = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0.25rem;
  height: 100%;
  background: ${Color.secondary};
`;

export const HazardousWasteContainer = styled.div`
  font-size: 1.25rem;
  ${Font.size.extrabold};
  color: ${Color.black};
  background-color: #fef103;
  padding: 2rem;
  width: fit-content;
  border: 1rem ${Color.black} solid;

  @media (max-width: 768px) {
    width: calc(100% - 6rem);
  }
`;

export const OrderedList = styled.ol`
  counter-reset: item;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  line-height: 1.5;
  color: #333;

  li {
    display: table;
    margin-bottom: 10px;
  }

  li:before {
    content: counter(item);
    counter-increment: item;
    display: table-cell;
    margin-right: 10px;
    padding-right: 20px;
    width: 20px;
    text-align: right;
    font-weight: bold;
    color: ${Color.secondary};
  }
`;

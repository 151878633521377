import Footer from "../components/Footer";
import Header from "../components/Header";
import Main from "../components/Main";
import { AppContainer, VerticalLine } from "../style/Style";

function App() {
  return (
    <AppContainer className="App">
      <VerticalLine />
      <Header />
      <Main />
      <Footer />
    </AppContainer>
  );
}

export default App;

import { useState } from "react";
import { toast } from "react-toastify";
import { Passport } from "../types";
import { useNavigate } from "react-router-dom";

// Custom hook that sends form data to an API endpoint via POST method
export const useSubmitForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null | unknown>(null);
  let navigate = useNavigate();

  const submitForm = async (formValues: Passport) => {
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://api.waste-passport.com/waste-passport/api/passport",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      // If the response is successful, return the response body
      toast.success("New passport created!", {
        autoClose: 2500,
        onClose: () => {
          // Redirect to the desired route after the toast notification disappears
          navigate("/success");
        },
      });
      return await response.json();
    } catch (error) {
      // If there's an error, set the error state and return null
      setError(error);
      toast.error("Failed to submit form");
      return null;
    } finally {
      // Set loading state to false when the request is complete
      setIsLoading(false);
    }
  };

  // Return the submitForm function, isLoading state, and error state as an object
  return { submitForm, isLoading, error };
};

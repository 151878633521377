import styled from "styled-components";
import { Color, Font } from "./Style";

export const ButtonSubmitPrimary = styled.button`
  background-color: ${Color.secondary};
  color: ${Color.white};
  ${Font.size.bold}
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${Color.accent};
    box-shadow: 0 2px 4px ${Color.rgba.light.green};
  }
`;

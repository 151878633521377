import Logo from "../img/logo-white.svg";
import { FooterContainer, FooterLogo } from "../style/Style";

export default function Footer() {
  return (
    <FooterContainer>
      <div>
        <p>&copy;2023</p>
        {/* <p>&copy;2023 - Peter Kisel</p> */}
        {/* <p>peter[at]raavn.net</p> */}
      </div>
      <div>
        <FooterLogo src={Logo} alt="logo" />
      </div>
    </FooterContainer>
  );
}

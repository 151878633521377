import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import useFetchPassport from "../../hooks/use-fetch-passport";
import { Color, Font, HazardousWasteContainer } from "../../style/Style";

const H2 = styled.h2`
  color: ${Color.blue};
  font-size: 2rem;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const LoaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid ${Color.accent};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${LoaderAnimation} 1.5s linear infinite;
`;

const JourneyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1.25rem;
`;

const PinContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Pin = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${Color.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PinText = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0;
`;

const Line = styled.div`
  border-left: 1px dashed ${Color.greyLight};
  margin: 0.5rem 0 0.5rem 1.25rem;
  height: 3rem;
  padding-left: 1rem;
  padding-top: 1rem;
  color: ${Color.grey};
  font-size: 0.75rem;
`;

const Origin = styled.p`
  font-size: 1rem;
  ${Font.size.bold};
  color: ${Color.black};
  padding-left: 0.5rem;
`;

const Destination = styled.p`
  font-size: 1rem;
  ${Font.size.bold};
  color: ${Color.black};
  padding-left: 0.5rem;
`;

export default function PassportViewScene() {
  let { passport_id } = useParams();
  const { data, loading } = useFetchPassport(passport_id!);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <QRCode
        value={`https://waste-passport.com/view/${passport_id}`}
        style={{ height: "auto", maxWidth: "30rem", width: "100%" }}
      />
      <p>id: {passport_id}</p>
      {data && (
        <>
          <H2>{data.title}</H2>
          {/* Hazard  */}
          {data.hazardous_waste === true && (
            <HazardousWasteContainer>Hazardous waste</HazardousWasteContainer>
          )}

          {/* Journey */}
          <JourneyContainer>
            <PinContainer>
              <Pin>
                <PinText>{data.origin.charAt(0)}</PinText>
              </Pin>
              <Origin>{data.origin}</Origin>
            </PinContainer>

            <Line>Transport via: {data.transport_method}</Line>

            <PinContainer>
              <Pin>
                <PinText>{data.destination.charAt(0)}</PinText>
              </Pin>
              <Destination>{data.destination}</Destination>
            </PinContainer>
          </JourneyContainer>
          <p>{data.consignment_notes}</p>
          <p>{data.conformity_declaration}</p>
          <p>Created: {data.created_at}</p>
          <p>Updated: {data.updated_at}</p>
        </>
      )}
    </>
  );
}
